<template>
  <div class="home">
    <CommonHeader :title="source ? '选择收货地址' : '我的地址'" />
    <div class="address-list">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="`list${index}`"
        @click="changeAddressAction(item)"
      >
        <div class="top flex justify-b align-items-c ft14 fw500">
          <div>{{ item.real_name }}</div>
          <div>{{ item.phone }}</div>
        </div>
        <div class="c ft12 color-gray">{{ item.province }} {{ item.city }} {{ item.district }} {{ item.detail }}</div>
        <div class="bottom flex justify-b align-items-c">
          <div
            class="flex align-items-c"
            @click.stop="setDefaultAction(index)"
          >
            <img
              v-if="item.is_default && !source"
              class="l-icon"
              src="https://artimg2.artart.cn/image/20220122/bdf3dafedcf127c6d55469ce06046c67.png"
              alt=""
            >
            <img
              v-else
              class="l-icon"
              src="https://artimg2.artart.cn/image/20220122/703d84e19ec0021197b736e5c801dcd7.png"
              alt=""
            >
            <div class="ft12 fw500">{{source ? '选择' : '设为默认'}}</div>
          </div>
          <div class="flex align-items-c">
            <div
              class="img"
              @click.stop="editAction(index)"
            >
              <img
                src="https://artimg2.artart.cn/image/20220214/0d0d37537823d095be74a11bb83057c1.png"
                alt=""
              >
            </div>
            <div
              class="img"
              @click.stop="delAction(index)"
            >
              <img
                src="https://artimg2.artart.cn/image/20220214/c89a9135000393048f1a4a2227798473.png"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="add-btn"
      :style="rightBtnStyle"
      @click="addAction"
    >+新建地址</div>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { ref, computed, onBeforeMount } from "vue";
import { Toast, Dialog } from "vant";
import { useStore } from "vuex";
import { addressList, setDefaultAddress, delAddress } from "@/api/user";
import { isWeiXin } from "@/utils/util";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const source = ref("");

    const list = ref([]);

    onBeforeMount(() => {
      source.value = route.query.buy || "";
      addressList()
        .then((res) => {
          list.value = res.data.list;
        })
        .catch((message) => Toast(message));
    });

    // 左按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });

    const addAction = () => {
      router.push("/users/address_detail");
    };

    let saveLock = false;
    const setDefaultAction = (index) => {
      // 设为默认
      if (list.value[index].is_default) return false;
      if (saveLock) return false;
      saveLock = true;
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });

      setDefaultAddress(list.value[index].address_id)
        .then(() => {
          list.value.forEach((m) => {
            if (m.is_default) m.is_default = 0;
          });
          list.value[index].is_default = 1;
          Toast.clear();
          Toast("操作成功");
          saveLock = false;
        })
        .catch((message) => {
          Toast.clear();
          Toast(message);
          saveLock = false;
        });
    };

    const delAction = (index) => {
      // 删除
      Dialog.confirm({
        title: "确定删除吗？",
      }).then(() => {
        if (saveLock) return false;
        saveLock = true;
        Toast.loading({
          forbidClick: true,
          duration: 0,
        });

        delAddress(list.value[index].address_id)
          .then(() => {
            list.value.splice(index, 1);
            Toast.clear();
            Toast("操作成功");
            saveLock = false;
          })
          .catch((message) => {
            Toast.clear();
            Toast(message);
            saveLock = false;
          });
      });
    };

    const editAction = (index) => {
      // 修改
      router.push({
        path: "/users/address_detail",
        query: { id: list.value[index].address_id },
      });
    };

    const changeAddressAction = (item) => {
      // 选中收货地址 只有是上一级页面是确认订单页才执行
      if (source.value) {
        const isWx = isWeiXin();
        if (isWx) {
          // eslint-disable-next-line no-undef
          wx.miniProgram.getEnv((rrr) => {
            if (rrr.miniprogram) {
              // eslint-disable-next-line no-undef
              wx.miniProgram.postMessage({
                data: {
                  setAddressId: item.address_id,
                },
              });
              // eslint-disable-next-line no-undef
              wx.miniProgram.navigateBack();
              return false;
            } else {
              localStorage.setItem("address_id", item.address_id);
              router.back();
            }
          });
        } else {
          localStorage.setItem("address_id", item.address_id);
          router.back();
        }
      }
    };

    return {
      list,
      rightBtnStyle,
      source,

      addAction,
      setDefaultAction,
      delAction,
      editAction,
      changeAddressAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #f7f7f7;
  padding-bottom: 200px;
}
.address-list {
  .item {
    margin: 32px;
    background: #fff;
    padding: 22px 32px 32px 32px;
    border-radius: 8px;

    .c {
      margin-top: 8px;
    }

    .bottom {
      margin-top: 16px;
      img {
        display: block;
      }
      .l-icon {
        width: 48px;
        height: 48px;
        margin-right: 8px;
      }

      .img {
        width: 32px;
        height: 48px;
        display: flex;
        align-items: center;
        padding-left: 32px;
      }
    }
  }
}
.add-btn {
  position: fixed;
  bottom: 70px;
  left: 24px;
  right: 24px;
  height: 96px;
  text-align: center;
  line-height: 96px;
}
</style>